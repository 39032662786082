<template>
  <div>
    <b-container>
      <br />
      <div class="loading" v-if="fetchData">
        <load-data></load-data>
      </div>
      <b-row v-else>
        <b-col md="12">
          <h4>Create Questions for {{ exam.name }}</h4>
          <!-- <p>{{ responseErrors }}</p> -->
          <!-- <p>{{ questions }}</p> -->
          <b-button
            variant="white"
            v-if="questions.length == 0 && !createQuestions"
            @click="createQuestions = true && prepareQuestions()"
            class="text-primary"
            ><i class="fas fa-plus-square fa-2x"></i
          ></b-button>
          <b-form v-if="questions.length != 0 && !createQuestions">
            <h6 class="alert alert-success text-center" v-if="responseSuccess.created">
              {{ responseSuccess.created[0] }}
            </h6>
            <h6 class="alert alert-success text-center" v-if="responseSuccess.updated">
              {{ responseSuccess.updated[0] }}
            </h6>
            <div v-for="(question, questionIndex) in questions" :key="questionIndex">
              <div>
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <div>
                      <b-form-input
                        style="width: 100px"
                        placeholder="Q No."
                        class="text-center"
                        :class="{
                          'is-invalid': responseErrors['question' + questionIndex],
                        }"
                        v-model="question.questionNumber"
                        :disabled="question.disabled"
                      ></b-form-input>
                      <span
                        class="text-danger"
                        v-if="
                          responseErrors['question' + questionIndex] &&
                          responseErrors['question' + questionIndex].question_no
                        "
                        >{{
                          responseErrors["question" + questionIndex].question_no[0]
                        }}</span
                      >
                    </div>
                    <span
                      class="btn m-0 p-0 text-info"
                      @click="question.disabled = false"
                      v-if="examQuestions.length != 0 && question.disabled"
                      ><i class="fas fa-edit"></i
                    ></span>
                    <span
                      class="btn m-0 p-0 text-info"
                      @click="
                        updateExamQuestion(
                          question.questionId,
                          question.questionNumber,
                          question.question,
                          question.attachment,
                          questionIndex
                        )
                      "
                      v-if="
                        examQuestions.length != 0 &&
                        !question.disabled &&
                        question.questionId
                      "
                      ><i :class="updateBtn.text"></i
                    ></span>
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-form-input
                    placeholder="Question"
                    v-model="question.question"
                    :class="{
                      'is-invalid': responseErrors['question' + questionIndex],
                    }"
                    :disabled="question.disabled"
                    class="text-center"
                  ></b-form-input>
                  <span
                    class="text-danger"
                    v-if="
                      responseErrors['question' + questionIndex] &&
                      responseErrors['question' + questionIndex].name
                    "
                    >{{ responseErrors["question" + questionIndex].name[0] }}</span
                  >
                </b-form-group>
                <b-form-group class="text-right" v-if="!question.disabled">
                  <b-form-file
                    multiple
                    :ref="'attachQuestions' + questionIndex"
                    style="display: none"
                    class="text-center"
                    v-model="question.attachment"
                    @change="theAttachment()"
                  ></b-form-file>
                  <div>
                    <span
                      class="btn text-info p-0 m-0"
                      @click="attachQuestions(questionIndex)"
                      ><i class="fas fa-paperclip fa-2x"></i
                    ></span>
                    <div class="d-flex flex-column">
                      <span
                        class="text-dark ml-3"
                        v-for="(file, attaachmentIndex) in question.attachment"
                        :key="attaachmentIndex"
                      >
                        <span>
                          {{ file.name
                          }}<span
                            class="btn p-0 m-0 ml-2 text-danger"
                            @click="question.attachment.splice(attaachmentIndex, 1)"
                            ><i class="fas fa-trash"></i
                          ></span>
                        </span>
                      </span>

                      <div
                        v-if="question.files && question.files.length != 0"
                        class="d-flex flex-column"
                      >
                        <span
                          class="text-dark ml-3"
                          v-for="(file, fileIndex) in question.files"
                          :key="fileIndex"
                        >
                          <div class="d-flex align-items-baseline">
                            <a
                              :href="userAttachments + file.file_path + file.file_name"
                              class="text-decoration-none d-flex"
                              target="_blank"
                            >
                              {{ file.file_name }} <br />
                            </a>
                            <span
                              class="btn p-0 m-0 ml-2 text-danger"
                              @click="
                                deleteAttachment(question.questionId, fileIndex, file.id)
                              "
                              ><i class="fas fa-trash"></i
                            ></span>
                          </div>
                        </span>
                        <!-- </span> -->
                      </div>
                    </div>
                  </div>
                </b-form-group>

                <div>
                  <b-form-group v-if="questionIndex == questions.length - 1">
                    <div class="">
                      <b-button
                        variant="primary"
                        size="md"
                        @click="createExamQuestion(questionIndex, true)"
                        :disabled="
                          questions[questions.length - 1].questionId &&
                          !questionBtn.disabled
                            ? true
                            : false
                        "
                        >Add Question</b-button
                      >
                    </div>
                    <br />
                    <div class="text-center">
                      <b-button
                        variant="success"
                        type="submit"
                        size="lg"
                        @click="createExamQuestion(questionIndex, false)"
                        :disabled="
                          questions[questions.length - 1].questionId &&
                          !questionBtn.disabled
                            ? true
                            : false
                        "
                        >{{ questionBtn.text }}</b-button
                      >
                    </div>
                  </b-form-group>
                </div>
              </div>
            </div>
            <br />
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
export default {
  async created() {
    this.fetchData = await true;
    // await this.prepareQuestions();
    await this.getExam();
    this.userAttachments = this.$store.getters.getAPIKey.userAttachments;
  },
  data() {
    return {
      userAttachments: null,
      fetchData: false,
      createQuestions: false,
      questions: [],
      examQuestions: [],
      mainAttachment: null,
      exam: {},
      questionBtn: {
        text: "Submit",
        disabled: false,
      },
      updateBtn: {
        text: "fas fa-check-circle",
      },
      responseErrors: {},
      responseSuccess: {},
    };
  },
  methods: {
    async createExamQuestion(questionIndex, addQuestion) {
      this.questionBtn.text = "Please wait....";
      this.questionBtn.disabled = true;
      if (!this.questions[this.questions.length - 1].questionId) {
        const url = this.$store.getters.getAPIKey.assignmentQuestions;

        const examQuestion = {
          question_no: this.questions[this.questions.length - 1].questionNumber,
          name: this.questions[this.questions.length - 1].question,
          maximum_mark: this.exam.maximum_mark,
          assignment_id: this.exam.id,
        };
        axios
          .post(url, examQuestion)
          .then((response) => {
            if (this.questions[this.questions.length - 1].attachment.length != 0) {
              this.addAttachment(
                this.questions[this.questions.length - 1].attachment,
                response.data.id,
                addQuestion
              );
            }
            if (this.questions[this.questions.length - 1].attachment.length == 0) {
              this.responseSuccess["created"] = ["Questions Created Successfully"];

              this.clearResponse(addQuestion);
              this.getExamQuestions(addQuestion);
            }
          })
          .catch((error) => {
            this.responseErrors[`question${questionIndex}`] = {};
            this.responseErrors[`question${questionIndex}`] = error.response.data.errors;
            this.clearResponse();
          });
        // }
      } else {
        this.addQuestion();
        this.clearResponse();
      }
    },
    addAttachment(attchmentData, examQuestionId, addQuestion) {
      attchmentData.forEach((attachment, attachmentIndex) => {
        let index = attachmentIndex;
        const fd = new FormData();
        fd.append("attachment", attachment);
        const url =
          this.$store.getters.getAPIKey.assignmentQuestions +
          `/${examQuestionId}/attachments`;
        axios
          .post(url, fd)
          .then(() => {
            if (index == attchmentData.length - 1) {
              this.responseSuccess["created"] = ["Questions Created Successfully"];
              this.clearResponse(addQuestion);
              this.getExamQuestions(addQuestion);
            }
          })
          .catch((error) => {
            this.clearResponse();
            error;
          });
      });
    },
    updateExamQuestion(id, question_no, name, attachment, questionIndex) {
      this.updateBtn.text = "spinner-border text-info";
      const url = this.$store.getters.getAPIKey.assignmentQuestions + `/${id}`;
      const examQuestion = {
        question_no: question_no,
        name: name,
        attachment: attachment,
        maximum_mark: this.exam.maximum_mark,
        assignment_id: this.exam.id,
      };
      axios
        .put(url, examQuestion)
        .then((response) => {
          if (this.questions[questionIndex].attachment.length != 0) {
            this.addAttachment(this.questions[questionIndex].attachment, id);
          }
          if (this.questions[questionIndex].attachment.length == 0) {
            this.responseSuccess["updated"] = ["Questions Updated Successfully"];
            this.getExamQuestions();
            this.clearResponse();
          }
          response;
        })
        .catch((error) => {
          this.responseErrors[`${this.questions.length - 1}`] = {};
          this.responseErrors[`${this.questions.length - 1}`] =
            error.response.data.errors;
          this.clearResponse();
        });
    },
    getExam() {
      const assignmentId = this.$route.params.assignment_id;
      const url = this.$store.getters.getAPIKey.assignment + `/${assignmentId}`;
      axios.get(url).then((response) => {
        this.exam = {};
        this.exam = response.data;
        this.getExamQuestions();
      });
    },
    getExamQuestions(addQuestion) {
      const url =
        this.$store.getters.getAPIKey.assignment + `/${this.exam.id}/assignmentquestions`;
      axios.get(url).then((response) => {
        this.examQuestions = response.data;
        if (this.examQuestions.length != 0) {
          this.questions = [];
          this.examQuestions.forEach((question) => {
            this.questions.push({
              questionId: question.id,
              questionNumber: question.question_no,
              question: question.name,
              attachment: [],
              files: question.files,
              disabled: true,
            });
          });
        }
        if (addQuestion) {
          this.addQuestion();
        }
        this.fetchData = false;
      });
    },
    async deleteAttachment(questionId, fileIndex, fileId) {
      this.fetchData = true;
      const question_id = await this.questions.findIndex((question) => {
        return questionId == question.questionId;
      });
      const url =
        this.$store.getters.getAPIKey.assignmentQuestions + `/attachments/${fileId}`;
      await axios
        .delete(url)
        .then((response) => {
          this.questions[question_id].files.splice(fileIndex, 1);
          response;
          this.fetchData = false;
        })
        .catch(() => {
          this.fetchData = false;
        });
    },
    prepareQuestions() {
      this.questions = [
        {
          // question_no: "",
          questionNumber: "",
          question: "",
          attachment: [],
        },
      ];
    },
    addQuestion() {
      this.questions.push({
        questionNumber: "",
        question: "",
        attachment: [],
      });
    },
    theAttachment(attachment) {
      attachment;
      // this.mainAttachment = this.questions[this.questions.length - 1].attachment;
    },
    removeQuestion(question) {
      this.questions.splice(question, 1);
    },
    attachQuestions(question) {
      let attachedQuestion = `${"attachQuestions" + question}`;
      this.$refs[attachedQuestion][0].$refs.input.click();
    },
    clearResponse(addQuestion) {
      this.questionBtn.text = "Submit";
      setTimeout(() => {
        if (this.responseSuccess.created) {
          if (!addQuestion) {
            this.$router.push({ name: "institute" });
          }
          this.updateBtn.text = "fas fa-check-circle";
          // this.prepareQuestions();
          this.responseSuccess = {};
        }
        if (this.responseSuccess.updated) {
          this.updateBtn.text = "fas fa-check-circle";
          // this.prepareQuestions();
          this.responseSuccess = {};
        }
        this.responseErrors = {};
        this.questionBtn.disabled = false;
      }, 3000);
    },
  },
  computed: {},
};
</script>
